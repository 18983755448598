import { PoNVoid, Template } from '@/types'
import { calculateAspectRatio, cls, formatNumber, preventDefault, stopPropagation, whisper } from '@/utils'
import { likeTemplate, unlikeTemplate } from '@/service/template.service'
import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { usePathname, useRouter } from 'next/navigation'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import Video from '../video'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
// import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import Button from '../button'
import { useSetAtom } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom } from '@/atoms'
import IconFire2 from '@/public/assets/fire2.svg'
import ButtonGroup, { ButtonGroupItem } from '../button-group'
import IconHeartSolid from '@haiper/icons-svg/icons/solid/heart.svg'
import IconHeartOutline from '@haiper/icons-svg/icons/outline/heart.svg'
import IconChat from '@haiper/icons-svg/icons/outline/chat.svg'
import { useTemplateLikeCache } from '@/hooks/useLikeCache'
import { isNil } from 'lodash-es'
import { useCachedMyProfile } from '@/hooks/useMyProfile'

export interface TemplateCardProps {
  className?: string
  data?: Template
  onCreate?: (template: Template) => PoNVoid
  style?: React.CSSProperties
}

const pathnamesWithCreationInput = [/^\/$/, /^\/creations$/, /^\/explore$/, /^\/templates$/, /^\/template\/.+/]

export default function TemplateCard({ className, data, onCreate, style }: TemplateCardProps) {
  const router = useRouter()
  const canGotoDetail = data?.template_id && !data?.template_id.startsWith('demo:')
  const setCreationInput = useSetAtom(creationInputAtom)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)
  const { data: profle } = useCachedMyProfile()
  const pathname = usePathname()
  const coverAspectStyle: CSSProperties = useMemo(() => {
    const { width, height } = data?.cover_image_spec ?? {}
    const aspectRatio = calculateAspectRatio(width, height)
    return {
      aspectRatio,
    }
  }, [data?.cover_image_spec])

  const handleGotoDetail = useCallback(() => {
    if (canGotoDetail) {
      router.push(`/template/${data?.template_id}`)
    }
  }, [data, router, canGotoDetail])

  const commentCount = data?.comment_num ?? 0

  const _liked = !!data?.commits?.is_like

  const likeCache = useTemplateLikeCache(data?.template_id ?? '')
  const liked = likeCache.liked ?? _liked

  const likedCountDelta = isNil(likeCache.liked) || likeCache.liked === _liked ? 0 : likeCache.liked ? 1 : -1

  const likeCount = Math.max(0, (data?.commits?.likes_count ?? 0) + likedCountDelta)

  const handleOpenComment = useCallback(() => {
    handleGotoDetail()
  }, [handleGotoDetail])

  const toggleLike = useCallback(async () => {
    likeCache.toggle(!liked)
    if (liked) {
      await unlikeTemplate(data?.template_id ?? '')
    } else {
      await likeTemplate(data?.template_id ?? '')
    }
  }, [liked, data, likeCache])

  const rightButtons: ButtonGroupItem[] = [
    {
      key: 'favorite',
      icon: liked ? IconHeartSolid : IconHeartOutline,
      className: '',
      label: String(likeCount || ''),
      tooltip: { content: 'Like' },
      iconClassname: cls('size-5', liked ? 'text-border-critical-hover' : 'text-icon'),
      visible: true,
      onClick: toggleLike,
    },
    {
      key: 'comment',
      icon: IconChat,
      label: String(commentCount || ''),
      className: '',
      iconClassname: 'size-5 text-icon',
      tooltip: { title: 'Comment', content: '' },
      visible: true,
      onClick: handleOpenComment,
    },
  ]

  const handleCreate = useCallback(
    (e: any) => {
      preventDefault(e)
      stopPropagation(e)

      if (!data?.template_id) {
        return
      }

      setCreationInput((prev) => {
        return {
          ...prev,
          mode: undefined,
          expanded: true,
        }
      })

      setActiveTemplateId(data?.template_id)

      const needRedirect = !pathnamesWithCreationInput.some((p) => p.test(pathname))

      if (needRedirect) {
        router.push('/creations')
      }

      onCreate?.(data)
    },
    [data, router, pathname, setCreationInput, setActiveTemplateId, onCreate],
  )

  const [coverLoadFailed, setCoverLoadFailed] = useState(false)

  const coverVideo = data?.examples?.[0]?.media_url

  return (
    <div
      className={cls(
        'group rounded-xl p-2 bg-surface flex flex-col gap-2 cursor-pointer border-2 border-b-4 border-border hover:border-border-hover overflow-hidden h-max max-h-max',
        canGotoDetail ? 'cursor-pointer' : 'cursor-default',
        className,
      )}
      style={style}
      aria-label='template card'
      onClick={handleGotoDetail}
    >
      <div className='flex flex-col items-center w-full justify-between text-text' aria-label='template card footer'>
        <div className='flex items-center w-full justify-between h-8 md:px-1 text-text gap-2'>
          <span className='text-body-sm font-medium tracking-15 line-clamp-2 leading-4 md:leading-5'>{data?.name}</span>
          <div className='flex items-center gap-2'>
            <Button className='size-8 p-0' tooltip='View detail'>
              <ChevronRight className='size-5 text-icon' />
            </Button>
          </div>
        </div>
      </div>
      <div className='relative w-full rounded-md overflow-hidden' style={coverAspectStyle}>
        {coverLoadFailed || (!data?.cover_image && !coverVideo) ? (
          <div
            className='w-full rounded-md overflow-hidden bg-surface-subdued flex items-center justify-center text-body-lg font-medium tracking-32'
            style={coverAspectStyle}
          >
            {data?.name}
          </div>
        ) : coverVideo ? (
          <Video
            loop
            // controls
            playOnHover
            resetOnBlur
            muted
            playsInline
            src={coverVideo}
            poster={data?.cover_image}
            className='w-full h-full object-cover object-top'
            onError={() => setCoverLoadFailed(true)}
            onClick={handleGotoDetail}
          />
        ) : (
          <img
            src={data?.cover_image}
            alt={data?.name}
            className='w-full h-full object-scale-down'
            onError={() => setCoverLoadFailed(true)}
          />
        )}
      </div>
      <div className='flex flex-col items-center w-full justify-between text-text' aria-label='template card footer'>
        <div className='flex items-center w-full justify-between laptop:h-9 md:px-1 text-text gap-1 laptop:gap-2 flex-col laptop:flex-row'>
          <Button
            variant='outline'
            className='h-9 p-[6px] gap-0 w-full laptop:w-auto'
            tooltip='Create'
            tooltipProps={{ asChild: true }}
            onClick={handleCreate}
          >
            <IconFire2 className='size-5 text-icon' />
            <span className='px-1 text-icon'>{formatNumber(data?.creation_num ?? 0)}</span>
            <IconPlus className='size-5 text-icon ml-auto' />
          </Button>
          <ButtonGroup variant='transparent' className='h-9 self-end' items={rightButtons} />
        </div>
      </div>
    </div>
  )
}
