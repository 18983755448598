'use client'

import TemplateCard from './card'
import Button from '@/components/button'
import { Template } from '@/types'
import MagicWaterfall from '../waterfall/magic'
import IconRefresh from '@haiper/icons-svg/icons/outline/refresh-cw.svg'
import IconMenuCircleHor from '@haiper/icons-svg/icons/outline/menu-circle-hor.svg'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { cls, whisper } from '@/utils'
import useGridColumns from '@/hooks/useGridColumns'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { shuffle as lodashShuffle } from 'lodash-es'
import { useWindowSize } from 'usehooks-ts'

export interface TemplateSubGroupProps {
  className?: string
  name: string
  category: string
  label: string
  templates: Template[]
  paddingSize?: number
  containerRef?: React.RefObject<HTMLDivElement>
  controls?: {
    change?: 'top' | 'bottom'
    more?: 'top' | 'bottom'
  }
  onLoadMore?: () => void
  onCreate?: () => void
  magic?: boolean
  shuffle?: boolean
}

export default function TemplateSubGroup({
  className,
  name,
  category,
  label,
  controls,
  templates,
  paddingSize = 0,
  magic,
  shuffle,
  onLoadMore,
  onCreate,
  containerRef,
}: TemplateSubGroupProps) {
  const { isBelowMd } = useBreakpoint('md')

  const gridStyle = cls('w-full grid grid-cols-2 md:grid-cols-4 gap-3')
  const columnCount = useGridColumns(gridStyle)
  const gapSize = 3 * 4
  // const templateCardWidth = ((ref.current?.clientWidth ?? 0) - paddingSize * 2 - (columnCount - 1) * gapSize) / columnCount
  const templateCardWidth =
    ((containerRef?.current?.clientWidth ?? 0) - paddingSize * 2 - (columnCount - 1) * gapSize) / columnCount

  useWindowSize()

  const [visibleItemCount, setVisibleItemCount] = useState(4)

  const templateCardStyle: CSSProperties = useMemo(() => {
    return {
      width: `${templateCardWidth}px`,
    }
  }, [templateCardWidth])

  const [refreshKey, setRefreshKey] = useState(nanoid())

  const filteredTemplates = useMemo(() => {
    if (!refreshKey) {
      return []
    }
    const shuffledTemplates = shuffle ? lodashShuffle(templates) : [...templates]
    const result: Template[] = []
    let availableCount = visibleItemCount
    let lastHorizontalIndex = -1

    while (availableCount > 0) {
      const template = shuffledTemplates.pop()
      if (!template) {
        break
      }
      result.push(template)

      const isVertical =
        template.cover_image_spec?.height &&
        template.cover_image_spec?.width &&
        template.cover_image_spec.height > template.cover_image_spec.width
      if (isVertical || !magic) {
        availableCount--
      } else {
        availableCount -= 0.5
        lastHorizontalIndex = result.length - 1
      }
    }

    if (availableCount < 0) {
      // remove the last horizontal item
      result.splice(lastHorizontalIndex, 1)
    } else if (availableCount === 0) {
      if (result.length === 2 * visibleItemCount) {
        // if all the items are horizontal, only show half of them
        result.splice(visibleItemCount)
      }
    }

    const sorted = result.sort((a, b) => {
      if (
        a.cover_image_spec?.width &&
        b.cover_image_spec?.width &&
        a.cover_image_spec?.height &&
        b.cover_image_spec?.height
      ) {
        const arA = a.cover_image_spec.width / a.cover_image_spec.height
        const arB = b.cover_image_spec.width / b.cover_image_spec.height
        if (Math.abs(arA - arB) > 0.1) {
          return arA - arB
        }
      }
      return a.rank - b.rank
    })
    return sorted
  }, [templates, visibleItemCount, refreshKey, magic, shuffle])

  const refresh = useCallback(() => {
    setRefreshKey(nanoid())
  }, [])

  const hasMore = useMemo(() => {
    return visibleItemCount < templates.length
  }, [visibleItemCount, templates])

  const loadMore = useCallback(() => {
    if (onLoadMore) {
      onLoadMore()
      return
    }
    setVisibleItemCount((prev) => prev + 4)
  }, [onLoadMore])

  if (!templates.length) {
    return null
  }

  return (
    <div key={name} className={cls('flex flex-col w-full gap-3 max-w-full', className)}>
      <div className='w-full flex items-center justify-between' aria-label='title'>
        <span className='text-heading-md tracking-32 font-bold'>{label}</span>
        <div className='flex items-center gap-4'>
          {hasMore && controls?.change === 'top' && (
            <Button variant='link' className='px-0 py-1' onClick={refresh}>
              <div className='flex items-center gap-1'>
                <IconRefresh className='size-5 text-icon' />
                <span className='flex-1 text-text'>Change</span>
              </div>
            </Button>
          )}
          {hasMore && category !== 'hot' && controls?.more === 'top' && (
            <Button variant='link' className='px-0 py-1' onClick={loadMore}>
              <div className='flex items-center gap-1'>
                <IconMenuCircleHor className='size-5 text-icon' />
                <span className='flex-1 text-text'>More</span>
              </div>
            </Button>
          )}
        </div>
      </div>
      <MagicWaterfall aria-label='gallery' gutter={12} columns={isBelowMd ? 2 : 4}>
        {filteredTemplates.map((template) => {
          return (
            <TemplateCard
              key={template?.template_id}
              data={template}
              style={templateCardStyle}
              className='absolute transition-none'
              onCreate={onCreate}
            />
          )
        })}
      </MagicWaterfall>
      <div className='w-full flex justify-center items-center'>
        {hasMore && category !== 'hot' && controls?.more === 'bottom' && (
          <Button variant='outline' className='w-[91px] h-9' onClick={loadMore}>
            Load More
          </Button>
        )}
      </div>
    </div>
  )
}
